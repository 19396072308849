<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="500px"
        persistent
        transition="dialog-top-transition"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="6" v-if="use_balance">
                  <v-text-field
                    type="number"
                    autocomplete="off"
                    dense
                    v-model.number="editedItem.poids"
                    label="Poids Palette (kg)"
                    :rules="[(v) => !!v || 'Poids obligatoire']"
                    hide-spin-buttons
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    autocomplete="off"
                    v-model="editedItem.comment"
                    label="Comment"
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="save">
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <confirmdialog ref="confirm" />
  </div>
</template>

<script>
import CREATE_PALETTE from "../graphql/Production/CREATE_PALETTE.gql";
import UPDATE_PALETTE from "../graphql/Production/UPDATE_PALETTE.gql";
import { use_balance } from "print/data.js";
export default {
  name: "paletteform",
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
  },
  props: {
    palette: Object,
    fiche: Object,
    showForm: Boolean,
    list: Array,
  },
  data: () => ({
    valid: true,
    editedItem: {},
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
  }),

  computed: {
    use_balance() {
      return use_balance;
    },
    showDetail() {
      return this.showForm;
    },

    formTitle() {
      return this.editedItem.id === -1
        ? "Nouvelle Palette "
        : "Editer Palette  : " + this.editedItem.ref;
    },
  },
  watch: {},

  created() {
    this.editedItem = Object.assign({}, this.palette);
  },

  methods: {
    async close() {
      this.$emit("close", this.palette);
    },
    async save() {
      if (this.$refs.form.validate()) {
        if (this.editedItem.id > -1) {
          this.$apollo
            .mutate({
              mutation: UPDATE_PALETTE,
              variables: {
                palette: {
                  id: this.editedItem.id,
                  comment: this.editedItem.comment,
                  poids: this.editedItem.poids,
                  write_uid: this.$store.state.me.id,
                },
              },
            })
            .then(() => {
              this.snackbar_text = "Palette MAJ avec success";
              this.snackbar_color = "success";
              this.snackbar = true;
              this.list.splice(this.editedItem.index, 1, this.editedItem);
            })
            .catch((error) => {
              this.loading = false;
              this.snackbar_text = error.message;
              this.snackbar_color = "error";
              this.snackbar = true;
            });
        } else {
          this.$apollo
            .mutate({
              mutation: CREATE_PALETTE,
              variables: {
                palette: {
                  fiche_id: this.fiche.id,
                  machine_id: this.fiche.machine_id,
                  comment: this.editedItem.comment,
                  poids: this.editedItem.poids,
                  create_uid: parseInt(this.$store.state.me.id),
                },
              },
            })
            .then((data) => {
              this.snackbar_color = "success";
              this.snackbar_text = "Palette Ajoutée avec success";
              this.snackbar = true;
              this.editedItem.closed = 0;
              this.editedItem.nb_items = 0;
              this.editedItem.qte = this.editedItem.poids;
              this.editedItem.id = data.data.createPalette.id;
              this.editedItem.ref = data.data.createPalette.ref;
              this.editedItem.created_at = data.data.createPalette.created_at;
              this.list.unshift(this.editedItem);
              this.$emit("palette_add", this.editedItem);
            })
            .catch((error) => {
              this.loading = false;
              this.snackbar_color = "error";
              this.snackbar_text = error.message;
              this.snackbar = true;
            });
        }
        this.$refs.form.resetValidation();
        this.close();
      }
    },
  },
};
</script>
